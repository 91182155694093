@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 5px 10px 4px;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;

      .popover-inner {
        background: $color;
        color: black;
        padding: 24px;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, .1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }

.btn {
    @apply font-bold py-2 px-4 rounded mr-4 shadow
}

.btn-primary {
    @apply bg-blue-500 text-white
}

.btn-primary:hover {
    @apply bg-blue-700
}

.btn-alert {
    @apply bg-red-500 text-white
}

.btn-alert:hover {
    @apply bg-red-700
}

.btn-secondary {
    @apply bg-white text-gray-800 font-bold py-2 px-4 border border-gray-400
}

.btn-secondary:hover {
    @apply bg-gray-300
}

.btn-small {
    @apply bg-blue-500 text-white font-bold p-1 text-xs cursor-pointer rounded mr-2 shadow
}

.btn-primary:hover {
    @apply bg-blue-700
}


.listing {
    th {
        @apply py-4 px-6 bg-gray-200 font-bold uppercase text-sm text-gray-800 border-b border-gray-300
    }

    tr {
        &:hover {
            @apply bg-gray-200
        }
    }

    @apply text-left w-full border-collapse
}

.pagination {
    @apply flex w-auto font-sans justify-center
}



.pagination-page,
.pagination-next,
.pagination-prev {
    &.active {
        @apply font-bold
    }

    @apply block text-blue-400 px-3 py-2 border border-gray-400 bg-white
}

.pagination-inactive {
    @apply text-black
}




.vue-swatches {
    @apply appearance-none block w-full mb-3 leading-tight
}

.form-select {
    @apply block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight
}

.form-select:focus {
    @apply outline-none bg-white border-gray-500
}

.form-select-arrow {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700
}

.form-select-small {
    @apply block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-1 px-2 pr-8 rounded leading-tight
}

.form-select-small:focus {
    @apply outline-none bg-white border-gray-500
}

.form-select-small-arrow {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 text-gray-700
}

.form-label {
    @apply uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
}

.form-textarea {
    @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight
}

.form-textarea:focus {
    @apply outline-none bg-white border-gray-500
}

.form-text {
    @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight
}

.form-text:focus {
    @apply outline-none bg-white border-gray-500
}

.form-text-small {
    @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-1 px-2 mb-3 leading-tight
}

.form-text-small:focus {
    @apply outline-none bg-white border-gray-500
}

.form-textarea-dynamic {
    @apply appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight h-24
}

.form-textarea-dynamic:focus {
    @apply outline-none bg-white border-gray-500
}

.pallette-label {
    @apply mx-3 mb-6 w-full items-center px-2 py-2 bg-white text-blue-300 rounded-lg shadow-lg tracking-wide uppercase border border-blue-300 cursor-pointer
}

.pallette-label:focus {
    @apply bg-blue-300 text-blue-300
}

.mx-datepicker,
.mx-datepicker-range {
    width: 100% !important
}




.modal-container {
    @apply flex flex-col h-full
}

.modal-c-title {
    @apply text-lg font-bold
}

.modal-buttons {
    @apply w-full text-right p-4
}

.modal-button {
    @apply bg-white text-gray-800 font-bold py-2 px-4 border border-gray-400 rounded shadow
}

.modal-button:hover {
    @apply bg-gray-300
}

.modal-button-primary {
    @apply bg-blue-500 text-white font-bold py-2 px-4 border border-gray-400 rounded shadow
}

.modal-button-primary:hover {
    @apply bg-blue-700
}

.modal-content {
    @apply flex-1 mx-auto p-8 w-full
}
